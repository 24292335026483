import SmudgeGif from "../assets/images/smud/smudgecatv2.gif";

const AboutSMUD = () => {
  return (
    <div id="smudcat" className="fix">
      <div className="about-area ptb--120  bg_color--1">
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-5">
                <div className="thumbnail">
                  <img className="w-100" src={SmudgeGif} alt="About Images" />
                </div>
              </div>
              <div className="col-lg-7">
                <div className="about-inner inner">
                  <div className="section-title" style={{ textAlign: "left" }}>
                    <h2 className="title">
                      {"The future is"}{" "}
                      <span style={{ color: "#f9004d" }}>{" now"}</span>
                    </h2>
                    <p className="description">
                      Move over{" "}
                      <span style={{ textDecoration: "line-through" }}>
                        Doge Coin
                      </span>
                      , there's a new top dog in town, and it's a mischievous
                      kitty with attitude! <b>SMUDCAT</b> is the memecoin
                      sensation that'll make you ditch the "much wow" and
                      embrace the "much meow"!{" "}
                      <span style={{ color: "#ad984e" }}>^•ﻌ•^ฅ</span>
                      <span style={{ color: "red" }}>♡ </span>
                    </p>
                    <p className="description">
                      While Doge Coin may have its barks, <b>SMUDCAT</b> has its
                      roars of laughter. We're not just cute; we're hysterically
                      funny! Say goodbye to those predictable doggy memes; we're
                      bringing you the freshest, most viral Smudge Cat hilarity
                      that'll have you in stitches! 😼✨
                    </p>
                    <p className="description">
                      100% SMUDCAT tokens are dedicated for our paw-some
                      community. No trading taxes. LP tokens are burned. We're
                      geared up for a wild ride to the moon, fueled by fun and
                      memes only! 🚀
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSMUD;
