import React, { Component } from "react";
import { FaSuitcase, FaRocket } from "react-icons/fa";
import { GiMoonOrbit } from "react-icons/gi";

const AllItems = [
  {
    icon: <FaSuitcase />,
    title: "Phase One",
    descriptions: [
      "Token fair launch",
      "Burn Liquidity Pool tokens",
      "Renounce Ownership",
      "Trending on Coinmarketcap, CoinGecko",
      "Pack up for the Moon trip 🚀",
    ],
  },
  {
    icon: <FaRocket />,
    title: "Phase Two",
    descriptions: [
      "Churn out the finest SMUDCAT memes",
      "KOL partnerships on Twitter, Instagram, TikTok",
      "CEX listings",
      "SMUDCAT to the Moon 🚀🚀",
    ],
  },
  {
    icon: <GiMoonOrbit />,
    title: "Phase Three",
    descriptions: [
      "Overtake PEPE and DOGE",
      "Rule as the king of Memecoins",
      "Memes, memes and more memes",
      "SMUDCAT statue on the Moon 🚀🚀🚀",
    ],
  },
];

class ServiceList extends Component {
  render() {
    const { column } = this.props;
    const ServiceContent = AllItems.slice(0, this.props.item);

    return (
      <React.Fragment>
        <div className="row">
          {ServiceContent.map((val, i) => (
            <div className={`${column}`} key={i}>
              <div
                className="service service__style--2"
                style={{ maxHeight: "450px" }}
              >
                <div className="icon">{val.icon}</div>
                <div className="content">
                  <p class="description">
                    <h3 className="title">{val.title}</h3>
                    <ul>
                      {val.descriptions.map((item) => (
                        <li>{item}</li>
                      ))}
                    </ul>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}

const RoadMap = () => {
  return (
    <div id="roadmap" className="fix">
      <div className="service-area creative-service-wrapper ptb--120 bg_color--5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                <h2 className="title">The Roadmap</h2>
                <p>
                  “The roadmap is more what you'd call 'guidelines' than actual
                  rules."
                </p>
              </div>
            </div>
          </div>
          <div className="row creative-service">
            <div className="col-lg-12">
              <ServiceList
                item="3"
                column="col-lg-4 col-md-6 col-sm-6 col-12 text-left"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoadMap;
