import TokenomicsPNG from "../assets/images/smud/tokenomics.png";

const Tokenomics = () => {
  return (
    <div id="token" className="fix">
      <div className="about-area ptb--120  bg_color--1">
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-7">
                <div className="about-inner inner">
                  <div className="section-title" style={{ textAlign: "left" }}>
                    <h2 className="title">
                      {"Tokenomics is"}{" "}
                      <span style={{ color: "#f9004d" }}>{" simple"}</span>
                    </h2>
                    <h5 className="title">
                      Contract:{"  "}
                      <a
                        href="https://basescan.org/token/0x22dc834c3ff3e45f484bf24b9b07b851b981900f"
                        target="_blank"
                        style={{ color: "skyblue", marginLeft: "5rem" }}
                      >
                        0x22dc834c3ff3e45f484bf24b9b07b851b981900f
                      </a>
                    </h5>
                    <h5 className="title">
                      Total supply:{"  "}
                      <span style={{ color: "#f9004d", marginLeft: "3rem" }}>
                        500,000,000,000
                      </span>{" "}
                      SMUDCAT
                    </h5>
                    <p className="description">
                      Trade freely. No bullshit tax.
                    </p>
                    <h5 className="title">
                      Liquidity Pool:{" "}
                      <span style={{ color: "#f9004d", marginLeft: "2.5rem" }}>
                        475,000,000,000
                      </span>{" "}
                      SMUDCAT
                    </h5>
                    <p className="description">
                      95% of tokens were sent to the liquidity pool at launch.
                      The LP tokens{" "}
                      <a
                        href="https://basescan.org/tx/0x0496e16af929a987930e9dd99b0e977ffdabe3800ac2cbc2b837aadc03679320"
                        style={{ color: "skyblue" }}
                      >
                        were burned
                      </a>
                      . Contract ownership{" "}
                      <a
                        href="https://basescan.org/tx/0x14ac977462c7e7f8c0ed038f50db76911c6847c105a5ff13f88d5f6b3874e02d"
                        style={{ color: "skyblue" }}
                      >
                        was renounced.{" "}
                      </a>
                      <br />
                      Check out{" "}
                      <a
                        href="https://twitter.com/smudcat"
                        style={{ color: "skyblue" }}
                      >
                        our socials
                      </a>{" "}
                      for these big events!
                    </p>
                    <h5 className="title">
                      Reserved for CEX:{" "}
                      <span style={{ color: "#f9004d", marginLeft: "1.2rem" }}>
                        25,000,000,000
                      </span>{" "}
                      SMUDCAT
                    </h5>
                    <p className="description">
                      5% of tokens are reserved for future CEX listings. Locked
                      in{" "}
                      <a
                        href="https://basescan.org/tx/0x5fb71841f55988038c3a0da6ab642e248080c6cf3fd21b5c2e193f76d3570488"
                        style={{ color: "skyblue" }}
                      >
                        a smart contract
                      </a>
                      , safe and sound.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="thumbnail">
                  <img className="w-100" src={TokenomicsPNG} alt="Tokenomics" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tokenomics;
