import React, { Component } from "react";
import ReactDOM from "react-dom";

import "./index.scss";
import App from "./App";

class Root extends Component {
  render() {
    return (
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
