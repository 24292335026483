import TextLoop from "react-text-loop";

const SlideList = [
  {
    textPosition: "text-left",
    category: "🐾 What the Heck is a SMUDCAT? 🐾",
    description: "",
    buttonText: "Buy SMUDCAT",
    buttonLink:
      "https://www.sushi.com/swap?fromChainId=8453&fromCurrency=NATIVE&toChainId=8453&toCurrency=0x22DC834C3Ff3e45f484bF24B9B07b851B981900f",
  },
];

const WhatIsSMUD = () => {
  return (
    <div id="home" className="fix">
      <div className="slider-wrapper">
        {SlideList.map((value, index) => (
          <div
            className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--36"
            key={index}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className={`inner ${value.textPosition}`}>
                    {value.category ? <span>{value.category}</span> : ""}
                    <h1>Smudge Cat is</h1>
                    {/* <br /> */}
                    <h1 className="title">
                      <TextLoop>
                        <span> Meow-magic! 🐱✨ </span>
                        <span> Pawsitively Addictive! 🐾 </span>
                        <span> LOL-ing to the MOON! 🚀 </span>
                      </TextLoop>{" "}
                    </h1>
                    <h2>Just a meme. </h2>
                    {value.description ? (
                      <p className="description">{value.description}</p>
                    ) : (
                      ""
                    )}
                    {value.buttonText ? (
                      <div className="slide-btn">
                        <a
                          className="rn-button-style--2 btn-primary-color"
                          href={`${value.buttonLink}`}
                          target="_blank"
                        >
                          {value.buttonText}
                        </a>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhatIsSMUD;
