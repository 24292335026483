import React from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import BlogContent from "../elements/blog/BlogContent";

import WhatIsSMUD from "./WhatIsSMUD";
import AboutSMUD from "./AboutSMUD";
import Roadmap from "./Roadmap";
import Tokenomics from "./Tokenomics";

const LandingPage = () => {
  return (
    <div className="active-dark">
      <Helmet pageTitle="Smudge Cat AI" />

      <HeaderThree homeLink="/" logo="symbol-light" color="color-black" />

      <WhatIsSMUD />

      <AboutSMUD />

      <Roadmap />

      <Tokenomics />

      <FooterTwo />
      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </div>
  );
};

export default LandingPage;
